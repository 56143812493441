import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { AllDirectorVideos } from "../components/directors/all-directors-videos";
import { AllDirectorsGalleries } from "../components/directors/all-directors-galleries";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: #1d1d1b;
  border-right: 1px solid #1d1d1b;
  border-left: 1px solid #1d1d1b;
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 680px;

  margin: 0 auto;
  padding: 65px 20px 0 20px;

  text-align: left;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.04em;

  color: #fcfbfc;

  & .biography-text {
    max-width: 675px;
    margin: 0 auto;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const FoodAndDrinkDirectorsContent = ({ data, setPageType, location }) => {
  useEffect(() => {
    setPageType("directors");
  }, []);

  return (
    <>
      <Helmet
        title={`Food & Drink – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Food & Drink – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Food & Drink – Park Village`,
          },
        ]}
      />
      <Page>
        <AllDirectorsGalleries
          data={data.prismicFoodDirectors.data.full_width_carousel}
          location={location}
          videoLocation={`food-and-drink`}
        />

        <AllDirectorVideos
          data={data.prismicFoodDirectors.data.directors}
          videoLocation={`food-and-drink`}
        />

        <TextContainer>
          <div
            className="biography-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicFoodDirectors.data.text.html,
            }}
          />
        </TextContainer>
      </Page>
    </>
  );
};

const FoodAndDrinkDirectors = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <FoodAndDrinkDirectorsContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(FoodAndDrinkDirectors);

export const query = graphql`
  {
    prismicFoodDirectors {
      prismicId
      data {
        text {
          html
        }
        full_width_carousel {
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    html
                    title
                    description
                    embed_url
                    width
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
                uid
              }
            }
          }
        }
        directors {
          video_size
          video {
            document {
              ... on PrismicVideo {
                id
                uid
                data {
                  title {
                    text
                  }
                  brand {
                    text
                  }
                  vimeo_video {
                    html
                    embed_url
                    width
                    description
                    title
                    height
                  }
                  vimeo_mp4_file {
                    url
                  }
                  director {
                    uid
                    document {
                      ... on PrismicDirector {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { w: 2000 }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
